<template>
  <div class="newsfeed">
    <iframe
      class="newsfeed-iframe"
      width="850"
      height="6570"
      src="https://rss.app/embed/v1/list/_53iECM4MS8w34MWM"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'newsfeed',
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.newsfeed {
  margin: auto;

  .newsfeed-iframe {
    margin: 25px 10px;
  }
}

@media screen and (max-width: 500px) {
  .newsfeed-iframe {
    width: 95%;
    margin: 15px 0 !important;
    height: 7060px;
  }
}
</style>
