<template>
  <div class="newsfeed">
    <iframe
      class="newsfeed-iframe"
      width="850"
      height="6950"
      src="https://rss.app/embed/v1/list/_5lG57sWKZ9DB7wZP"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'newsfeed',
  data() {
    return {
      height: 1000,
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.newsfeed {
  margin: auto;

  .newsfeed-iframe {
    margin: 25px 10px;
  }
}

@media screen and (max-width: 500px) {
  .newsfeed-iframe {
    width: 95%;
    margin: 15px 0 !important;
    height: 7520px;
  }
}
</style>
